import { BrowserRouter } from 'react-router-dom'
import Login from '../Login'
import Kiosk from '../Kiosk/Bpo'
import Kiosk2 from '../Kiosk/SecurityAgency'
import Kiosk3 from '../Kiosk/Construction'
import Kiosk4 from '../Kiosk/Retail'
import Register from '../Register'
import Notfound from '../Error/404'
import Uploads from '../Uploads'
import { ThemeProvider } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Footer from '../../components/Footer'

import Drawer from '../Drawer'
import Appbar from '../AppBar'
import theme from '../../theme/themeOverride'
import MainContextContainer from '../../context/MainContext'
import AppRoutes from 'pages/Router'
import axios from "../../api";
import {API_ENDPOINT} from "../../utils/globalConstant";
import {useEffect} from "react";

const Main = () => {
    const getClientProfile = async () => {
        await axios.get(API_ENDPOINT.GET_CLIENT_PROFILE).then(response => {
            localStorage.setItem('clientProfile', response.data.results.clientProfile?? '');
        })
    }

    useEffect(() => {
        ;(async () => {
            getClientProfile()
        })()
    }, [])

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <MainContextContainer>
          <>
            {(() => {
              if (window.location.pathname === '/' || window.location.pathname === '/login') {
                  if(localStorage.getItem('jwt')) {
                      return window.location.replace('/home')
                  }else{
                      return (
                          <Login/>
                      )
                  }

              } else if(window.location.pathname === '/kiosk') {
                  if(localStorage.getItem('clientProfile') == "bpo"){
                      return (<Kiosk/>)
                  }else if(localStorage.getItem('clientProfile') == "security_agency"){
                      return (<Kiosk2/>)
                  }else if(localStorage.getItem('clientProfile') == "construction"){
                      return (<Kiosk3 />)
                  }else if(localStorage.getItem('clientProfile') == "retail"){
                      return (<Kiosk4/>)
                  }else if(localStorage.getItem('clientProfile') == "standard"){
                      return (<Kiosk4/>)
                  }

              } else if(window.location.pathname === '/register'){
                  return (<Register/>)
              } else if(window.location.pathname === '/unauthorized'){
                  return (
                      <Notfound />
                  )
              } else if(window.location.pathname == '/upload-sale'){
                  return (
                      <Uploads />
                  )
              }


                if(localStorage.getItem('jwt')) {
                    return (
                    <Box sx={{ display: 'flex' }}>
                        <Appbar />
                        <Drawer />
                        <Box
                            component='main'
                            id='main'
                            sx={{ flexGrow: 1, pt: 3, marginTop: '50px', minHeight: 'calc(100vh - 65px)' }}
                            display='flex'
                            flexDirection='column'
                            justifyContent='space-between'
                        >
                            <AppRoutes />
                            <Footer />
                        </Box>
                    </Box>
                    )
                }else{
                    return window.location.replace('/login')
                }


            })()}
          </>
        </MainContextContainer>
      </ThemeProvider>
    </BrowserRouter>
  )
}

export default Main
