import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'
import {
  Modal,
  Breadcrumb,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
  Button,
  InputGroup,
} from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import AttendancePresentIcon from 'asset/SvgComponent/AttendancePresentIcon'
import AttendanceAbsentIcon from 'asset/SvgComponent/AttendanceAbsentIcon'
import AttendanceHolidayIcon from 'asset/SvgComponent/AttendanceHolidayIcon'
import AttendanceHafhdayIcon from 'asset/SvgComponent/AttendanceHafhdayIcon'
import AttendanceLeaveIcon from 'asset/SvgComponent/AttendanceLeaveIcon'
import AttendanceDayoffIcon from 'asset/SvgComponent/AttendanceDayoffIcon'
import Location from 'asset/SvgComponent/Location'
import View from 'asset/SvgComponent/View'
import Write from 'asset/SvgComponent/Write'
import Calendar from 'asset/SvgComponent/Calendar'
import Pagination from 'components/Pagination'
import Loader from 'components/Loader'
import Alert from 'components/Alert'
import Stack from '@mui/material/Stack'
import { Avatar } from '@mui/material'
import './style.css'

import { Formik } from 'formik';
import {validationSchema, validationSchema2} from './validationSchema'
import axios from 'api/index'
import {
  API_ENDPOINT,
  ERROR_MESSAGE,
  IMAGE_BASE_URL, SUCCESS_MESSAGE,ATTENDANCE,
  TEXT
} from 'utils/globalConstant'
import { IAPI_Response } from 'api/types'
import {
  IEmployee,
  IAttendanceOverview,
  IAttendanceByUser,
  IOverview,
  IByUser,
  IDay,
  IWorksite,
  IWorksiteSubworksite,
  IWorksiteShift,
  IAttendanceShow,
  IEmployeeAttendance,
  IList,
  ILocationAndImages,
  IEmployeeAttendanceImagesToday
} from './types'
import {MapContainer, TileLayer} from "react-leaflet";
import LocationMarker from "components/Marker";
import {Link, useParams} from 'react-router-dom';
import Clock from "asset/SvgComponent/Clock";
import * as XLSX from "xlsx";
import Runningtime from 'components/RunningTime'
import Select from "components/ReactSelect";
import ReactToPrint from "react-to-print";
import PrintAttendanceComponent from "./printAttendanceComponent"
import AttendanceWorkingHolidayIcon from "asset/SvgComponent/AttendanceWorkingHolidayIcon";
import FileSaver from "file-saver";

export default function Index() {
  const now = new Date();
  const componentRef = useRef<HTMLDivElement>(null);

  const [key, setKey] = useState('attendance-overview')
  const [currentPage, setCurrentPage] = useState(0)
  const [lastPage, setLastPage] = useState(0)
  const [itemsPerPage, setPerPage] = useState(10)
  const [itemsCount, setItemCount] = useState(0)
  const [from, setFrom] = useState(0)

  const [currentPage1, setCurrentPage1] = useState(0)
  const [lastPage1, setLastPage1] = useState(0)
  const [itemsPerPage1, setPerPage1] = useState(10)
  const [itemsCount1, setItemCount1] = useState(0)

  const [currentPage2, setCurrentPage2] = useState(0)
  const [lastPage2, setLastPage2] = useState(0)
  const [itemsPerPage2, setPerPage2] = useState(10)
  const [itemsCount2, setItemCount2] = useState(0)
  const [from2, setFrom2] = useState(0)

  const [error, setError] = useState('')
  const [showError, setShowError] = useState(false)
  const [success, setSuccess] = useState('')
  const [showSuccess, setShowSuccess] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [attendaceOverview, setAttendanceOverview] = useState<IOverview[]>([])
  const [attendaceByUser, setAttendanceByUser] = useState<IByUser[]>([])
  const [attendanceToday, setAttendanceToday] = useState<IList[]>([])
  const [attendaceDay, setAttendanceDay] = useState<IDay[]>([])
  const [employees, setEmployee] = useState<IEmployee[]>([
    { 
      employee_id: 0,
      first_name: '',
      last_name: ''
    }
  ])

  const [employeeNo, setEmployeeNo] = useState('')
  const [dateByUser, setDateByUser] = useState({ 
    date_from: '',
    date_to: ''
  })

  const [showImage, setShowImage] = useState(false);
  const [showAllImage, setShowAllImage] = useState(false);
  const [showAllImageToday, setShowAllImageToday] = useState(false);
  const [showImageTodayAttendance, setShowImageTodayAttendance] = useState(false);
  const [showLocation, setLocationImage] = useState(false);
  const [showLocationTodayAttendance, setLocationImageTodayAttendance] = useState(false);
  const [location, setLocation] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })
  const [locationDetails, setLocationDetails] = useState({
    latitude: '',
    longitude: '',
    address: ''
  })
  const [selectedDate, setSelectedDate] = useState('');
  const [entries, setEntries] = useState(10);
  const [entriesForByUser, setEntriesForByUser] = useState(10)
  const [showEdit, setShowEdit] = useState(false);
  const [currentSelectedDate, setCurrentSelectedDate] = useState('');
  const [showEmployeeAttendance, setShowEmployeeAttendance] = useState({
    in_time: "",
    out_time: "",
    in_time_pm: "",
    out_time_pm: "",
    worksite: 0,
    subworksite: 0,
    shift: 0
  })
  const [uploadFile, setUploadFile] = React.useState({});
  const [showUpload, setShowUpload] = React.useState(false)
  const handleCloseUpload = () => setShowUpload(false)
  const [worksites, setWorksites] = useState<IWorksite[]>([])
  const [subworksites, setSubworksites] = useState<IWorksiteSubworksite[]>([])
  const [subworksitesForEdit, setSubworksitesForEdit] = useState<IWorksiteSubworksite[]>([])
  const [shifts, setShifts] = useState<IWorksiteShift[]>([])
  const [shiftsForEdit, setShiftsForEdit] = useState<IWorksiteShift[]>([])
  const [uploadDetails, setUploadDetails] = useState({worksite: 0, subworksite: 0, shift: 0})
  const [selectedAttendanceId, setSelectedAttendanceId] = useState(0);
  const [shiftType, setShiftType] = useState("Choose shift.")
  const [updateShiftType, setUpdateShiftType] = useState("Choose shift.")
  const [attendanceTodayCount, setAttendanceTodayCount] = useState(0)
  const [selectedEmployee, setSelectedEmployee] = React.useState(0);
  const [selectedEmployeeAttendance, setSelectedEmployeeAttendance] = useState(0);
  const [selectedEmployeeTodayAttendance, setSelectedEmployeeTodayAttendance] = useState(0);
  const [employeeOptions, setEmployeeOptions] = useState<{value: number, label: string}[]>([]);
  const [selectedEmployeeOption, setSelectedEmployeeOption] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
  const [selectedEmployeeOptionByUser, setSelectedEmployeeOptionByUser] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
  const [selectedEmployeeOptionOverview, setSelectedEmployeeOptionOverview] = useState<{value: number, label: string}>({value: 0, label: "All Employees"})
  const [selectedEmployeeNoOverview, setSelectedEmployeeNoOverview] = useState(0);
  const [selectedMonthOverview, setSelectedMonthOverview] = useState("");
  const [selectedYearOverview, setSelectedYearOverview] = useState("");
  const [attendanceByUserFilters, setAttendanceByUserFilters] = useState({
    date_from: "",
    date_to: "",
    employee_id: ""
  })
  const [selectedWorksiteOption, setSelectedWorksiteOption] = useState<{value: number, label: string}>({value: 0, label: "All Worksites"})
  const [selectedWorksiteOptionOverview, setSelectedWorksiteOptionOverview] = useState<{value: number, label: string}>({value: 0, label: "All Worksites"})
  const [worksiteOptions, setWorksiteOptions] = useState<{value: number, label: string}[]>([]);
  const [dropdownWorksite, setDropdownWorksite] = useState(0);
  const [dropdownWorksiteName, setDropdownWorksiteName] = useState('');
  const [attendanceImageLocation, setAttendanceImageLocation] = useState<ILocationAndImages[]>([]);
  const [attendanceImageLocationToday, setAttendanceImageLocationToday] = useState<ILocationAndImages[]>([]);
  const [currentPage3, setCurrentPage3] = useState(0)
  const [lastPage3, setLastPage3] = useState(0)
  const [itemsPerPage3, setPerPage3] = useState(10)
  const [itemsCount3, setItemCount3] = useState(0)
  const [from3, setFrom3] = useState(0)
  const [dateByDay, setDateByDay] = useState(new Date().toISOString().split("T")[0])

  const handleDisplayAddress = (address: string) => {

    if(address.length > 40){
      return address.slice(0, 40) + "...";
    }else{
      return address
    }

  }

  const handleChangeDropdownWorksiteOverview = (selectedOption:any) => {
    setSelectedWorksiteOptionOverview(selectedOption)

  }

  const handleChangeDropdownWorksite = (selectedOption:any) => {
    setDropdownWorksite(selectedOption.value);
    setDropdownWorksiteName(selectedOption.label)
    setSelectedWorksiteOption(selectedOption)

    if(selectedOption.value == "0"){
      setDropdownWorksiteName('')
    }

  }

  const handleChangeDropdown = (selectedOption: any) => {
    setSelectedEmployeeOption(selectedOption)
  }

  const handleChangeDropdownByUser = (selectedOption: any) => {
    setEmployeeNo(selectedOption.value)
    setSelectedEmployeeOptionByUser(selectedOption)
  }

  const handleChangeDropdownOverview = (selectedOption: any) => {
    setSelectedEmployeeOptionOverview(selectedOption)
    setSelectedEmployeeNoOverview(selectedOption.value)
  }


  const options = [
    {name: 'Swedish', value: 'sv'},
    {name: 'English', value: 'en'}
      ]
  const handleOpenUpload = () => {
    setShowUpload(true)
  }

  const handleCloseEdit = () => {
    setShowEdit(false)
    setShowEmployeeAttendance({
      in_time: "",
      out_time: "",
      in_time_pm: "",
      out_time_pm: "",
      worksite: 0,
      subworksite: 0,
      shift: 0
    })
  }

  const handleShowEdit = () => {
    setShowEdit(false)
  }

  const handleChangeInTime = (e: any) => {
    setShowEmployeeAttendance(prev => ({...prev, in_time: e.target.value}))
  }

  const handleChangeOutTime = (e:any) => {
    setShowEmployeeAttendance(prev => ({...prev, out_time: e.target.value}))
  }

  const handleChangeWorksite = (e:any) => {
    setUploadDetails(prev => ({...prev, worksite: e.target.value}))
    getSubworksites(e.target.value);
    getShifts(e.target.value);
  }

  const handleChangeSubworksite = (e:any) => {
    setUploadDetails(prev => ({...prev, subworksite: e.target.value}))
  }

  const handleChangeShift = (e:any) => {
    setUploadDetails(prev => ({...prev, shift: e.target.value}))
    shifts.map(shift => {
      if(shift.shifts.id == e.target.value){
        if(shift.shifts.isEightHourShift == 1){
          setShiftType("8 Hours Shift")
        }else if(shift.shifts.isEightHourShift == 0){
          setShiftType("12 Hours Shift")
        }else if(shift.shifts.isEightHourShift == 2){
          setShiftType("8 Hrs. and 4 Hrs. OT Shift")
        }

      }
    })
  }

  const handleChangeUpdateWorksite = (e:any) => {
    setShowEmployeeAttendance(prev => ({...prev, worksite: e.target.value}))
    getSubworksitesForEdit(e.target.value);
    getShiftsForEdit(e.target.value);
  }

  const handleChangeUpdateSubworksite = (e:any) => {
    setShowEmployeeAttendance(prev => ({...prev, subworksite: e.target.value}))
  }

  const handleChangeUpdateShift = (e:any) => {
    setShowEmployeeAttendance(prev => ({...prev, shift: e.target.value}))

    shiftsForEdit.map(shift => {
      if(shift.shifts.id == e.target.value){
        if(shift.shifts.isEightHourShift == 1){
          setUpdateShiftType("8 Hours Shift")
        }else if(shift.shifts.isEightHourShift == 0){
          setUpdateShiftType("12 Hours Shift")
        }else if(shift.shifts.isEightHourShift == 2){
          setUpdateShiftType("8 Hrs. and 4 Hrs. OT Shift")
        }

      }
    })
  }

  const handleChangeClockInPm = (e:any) => {
    setShowEmployeeAttendance(prev => ({...prev, in_time_pm: e.target.value}))
  }

  const handleChangeClockOutPm = (e:any) => {
    setShowEmployeeAttendance(prev => ({...prev, out_time_pm: e.target.value}))
  }

  const getWorkSite = async () => {
    setShowLoading(true)
    try {
      const response = await axios.get<IAPI_Response<IWorksite[]>>(API_ENDPOINT.ALL_WORK_SITE)
      const result = await response
      if (result?.data?.results?.length) {
        setWorksites(result?.data?.results)
        const worksites = result?.data?.results;
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Worksites"}]);
        worksites.map(worksite => {
          setWorksiteOptions(current => [...current, {value: worksite.id, label: worksite.work_site_name}]);
        })
      }else{
        setWorksites([])
        setWorksites([])
        setWorksiteOptions([])
        setWorksiteOptions(current => [...current, {value: 0, label: "All Worksites"}]);
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }


  const getSubworksites = async (id:any) => {
    try {
      const response = await axios.get<IAPI_Response<IWorksiteSubworksite[]>>(API_ENDPOINT.GET_SUB_WORK_SITE_BY_WORK_SITE+"/"+id)
      const result = await response
      if (result?.data?.results?.length) {
        setSubworksites(result?.data?.results)
      }else{
        setSubworksites([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getSubworksitesForEdit = async (id:any) => {
    if(id != 0 && id && id != ""){
      try {
        const response = await axios.get<IAPI_Response<IWorksiteSubworksite[]>>(API_ENDPOINT.GET_SUB_WORK_SITE_BY_WORK_SITE+"/"+id)
        const result = await response
        if (result?.data?.results?.length) {
          setSubworksitesForEdit(result?.data?.results)
        }else{
          setSubworksitesForEdit([])
        }
        setShowLoading(false)
      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }

  }

  const getShifts = async(id:any) => {
    try {
      const response = await axios.get<IAPI_Response<IWorksiteShift[]>>(API_ENDPOINT.GET_HOURS_SHIFT_BY_WORKSITE+"/"+id)
      const result = await response
      if (result?.data?.results?.length) {
        setShifts(result?.data?.results)
      }else{
        setShifts([])
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getShiftsForEdit = async(id:any) => {
    if(id != 0 && id && id != ""){
      try {
        const response = await axios.get<IAPI_Response<IWorksiteShift[]>>(API_ENDPOINT.GET_HOURS_SHIFT_BY_WORKSITE+"/"+id)
        const result = await response
        if (result?.data?.results?.length) {
          setShiftsForEdit(result?.data?.results)
        }else{
          setShiftsForEdit([])
        }
        setShowLoading(false)
      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

      setTimeout(() => {
        setShowError(false)
      }, 3000);
    }

  }

  const handleUploadAttendance = async() => {
    await axios.post(API_ENDPOINT.UPLOAD_ATTENDANCE, {...uploadDetails, 'uploadFile': uploadFile, 'employeeNo': employeeNo}).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(SUCCESS_MESSAGE.EMPLOYEE_SUCCESSFULLY_UPLOAD)
        setShowUpload(false)
        getAttendanceByUser(null, employeeNo, dateByUser.date_from, dateByUser.date_to, entriesForByUser)
      }
    }).catch(error => {
      setShowError(true)
      setError(error.response.data.message)
      setShowUpload(false)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleChangeUploadFile = (e:any) => {

    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      const bstr = evt?.target?.result;
      const wb = XLSX.read(bstr, { type: "binary"});
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {raw: false});
      setUploadFile(data);

    };
    reader.readAsBinaryString(file);
  }

  const handleUpdate = async () => {
    await axios.put(API_ENDPOINT.PUT_ATTENDANCE_FOR_JVD, {
      employee_attendance_id: selectedAttendanceId,
      employee_id: employeeNo,
      in_time: showEmployeeAttendance.in_time,
      out_time: showEmployeeAttendance.out_time,
      selectedDate: currentSelectedDate,
      worksite_id: showEmployeeAttendance.worksite,
      subworksite_id: showEmployeeAttendance.subworksite,
      shift_id: showEmployeeAttendance.shift,
      in_time_pm: showEmployeeAttendance.in_time_pm,
      out_time_pm: showEmployeeAttendance.out_time_pm
    }).then(response => {

      if (response?.data?.status * 1 === 1) {
        setShowSuccess(true)
        setSuccess(ATTENDANCE.UPDATE_ATTENDANCE)
        filterAttendanceByUser({employee_id:  employeeNo, date_from: dateByUser.date_from, date_to: dateByUser.date_to});
        // attendaceByUser.map((attendance, index) => {
        //   if(attendance.id == selectedAttendanceId){
        //     handleEdit(attendaceByUser[index + 1].id, moment(new Date(attendaceByUser[index + 1].date ?? "")).format('YYYY-MM-DD'));
        //   }
        // })
        setShowEdit(false)
      }
    }).catch(() => {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    })

    setTimeout(() => {
      setShowSuccess(false)
      setShowError(false)
    }, 3000);
  }

  const handleEdit = async (id:any, date:any) => {
    setSelectedAttendanceId(id)
    try {
      const res = await axios.get<IAPI_Response<IAttendanceShow>>(API_ENDPOINT.GET_ATTENDANCE_PER_USER_AND_PER_DATE_WITHOUT_WORKSITE, {
        params: {
          employee_attendance_id: id,
          employee_id: employeeNo
        }
      })
      const result = await res
      if (result?.data?.results) {
        setShowEmployeeAttendance({
          in_time: result?.data?.results.in_time ?? "",
          out_time: result?.data?.results.out_time ?? "",
          in_time_pm: result?.data?.results.in_time_pm ?? "",
          out_time_pm: result?.data?.results.out_time_pm ?? "",
          worksite: result?.data?.results.worksite_id ?? 0,
          subworksite: result?.data?.results.subworksite_id ?? 0,
          shift: result?.data?.results.shift_id
        })
        getSubworksitesForEdit(result?.data?.results.worksite_id)
        getShiftsForEdit(result?.data?.results.worksite_id);
      }
    } catch {
      console.log('There is something wrong')
    }

    setCurrentSelectedDate(date);
    setShowEdit(true);
  }

  const handleChangeEntries = (e:any) => {
    setEntries(e.target.value);
    getAttendanceOverview(
        null,
        selectedEmployeeNoOverview,
        null,
        null,
        e.target.value,
        selectedWorksiteOptionOverview.label
    );
  }

  const handleChangeEntriesForByUser = (e:any) => {
    setEntriesForByUser(e.target.value);
    getAttendanceByUser(
        null,
        attendanceByUserFilters.employee_id,
        attendanceByUserFilters.date_from,
        attendanceByUserFilters.date_to,
        e.target.value
    );
  }

  const handleCloseAllImage = () => setShowAllImage(false)
  const handleShowAllImage = async () => {
    if(employeeNo != "" && dateByUser.date_from != "" && dateByUser.date_to != ""){
      setShowAllImage(true)
      try {
        const res = await axios.get<IAPI_Response<ILocationAndImages[]>>(API_ENDPOINT.GET_ATTENDANCE_IMAGE_LOCATION_FOR_JVD, {
          params: {
            employee_id: employeeNo,
            date_from: dateByUser.date_from,
            date_to: dateByUser.date_to,
          },
        })
        const result = await res

        setAttendanceImageLocation([])
        if (result?.data?.results) {
          setAttendanceImageLocation(result?.data?.results)
        }
        setShowLoading(false)
      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }

    }else{
      setShowError(true)
      setError("Please select employee and date filter first.")
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleCloseAllImageToday = () => setShowAllImageToday(false)

  const handleShowAllImageToday = async (page_number: number | null) => {
      setShowAllImageToday(true)
      try {
        const res = await axios.get<IAPI_Response<IEmployeeAttendanceImagesToday>>(API_ENDPOINT.GET_ATTENDANCE_IMAGE_LOCATION_TODAY_FOR_JVD,{
          params: {
            page: page_number,
            date: dateByDay
          },
        })
        const result = await res

        setAttendanceByUser([])
        if (result?.data?.results) {
          setAttendanceImageLocationToday(result?.data?.results?.data)
          setCurrentPage3(result?.data?.results?.current_page)
          setLastPage3(result?.data?.results?.last_page)
          setPerPage3(result?.data?.results?.per_page)
          setItemCount3(result?.data?.results?.total)
          setFrom3(result?.data?.results?.from)
        }
        setShowLoading(false)
      } catch {
        setShowError(true)
        setError(ERROR_MESSAGE.SYSTEM_ERROR)
      }


    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const handleCloseImage = () => setShowImage(false)
  const handleShowImage = (id: any) => {
    setShowImage(true)
    setSelectedEmployeeAttendance(id);
  }
  const handleCloseImageTodayAttendance = () => setShowImageTodayAttendance(false)
  const handleShowImageTodayAttendance = (id: any) => {
    setShowImageTodayAttendance(true)
    setSelectedEmployee(id);
  }

  const handleCloseLocation = () => setLocationImage(false)
  const handleShowLocation = (id: any) => {
    setSelectedEmployeeAttendance(id);
    setLocationImage(true)
    setLocationDetails({
      latitude: "",
      longitude: "",
      address: ""
    });
  }

  const handleCloseLocationTodayAttendance = () => setLocationImageTodayAttendance(false)
  const handleShowLocationTodayAttendance = (id: any) => {
    setSelectedEmployee(id);
    setLocationImageTodayAttendance(true)
    setLocationDetails({
      latitude: "",
      longitude: "",
      address: ""
    });
  }

  const getLocation = (data: any) => {
    setLocation(data)
  }

  const handlePrint = () => {
    console.log("print")
  }
  
  const getAllEmployee = async () => {
    try {
      const res = await axios.get<IAPI_Response<IEmployee[]>>(API_ENDPOINT.ALL_EMPLOYEE)
      const result = await res
      if (result?.data?.results?.length) {
        setEmployee(result?.data?.results)
        const employees = result?.data?.results;
        setEmployeeOptions([])
        if(JSON.parse(localStorage.getItem('role') ?? "") == "Employee"){
          employees.map(employee => {
            if(employee.employee_id == Number(localStorage.getItem('employee_id'))){
              setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
              setSelectedEmployeeOption({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setSelectedEmployeeOptionOverview({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setSelectedEmployeeNoOverview(employee.employee_id)
              setSelectedEmployeeOptionByUser({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
              setEmployeeNo(employee.employee_id.toString());
            }

          })

        }else{
          setEmployeeOptions(current => [...current, {value: 0, label: "All Employees"}]);
          employees.map(employee => {
            setEmployeeOptions(current => [...current, {value: employee.employee_id, label: employee.first_name + " " + employee.last_name}]);
            if(localStorage.getItem('employee_id_by_user')){
              if(employee.employee_id.toString() == localStorage.getItem('employee_id_by_user')){
                setEmployeeNo(employee.employee_id.toString())
                setSelectedEmployeeOptionByUser({value: employee.employee_id, label: employee.first_name + " " + employee.last_name})
                localStorage.removeItem("employee_id_by_user");
              }

            }
          })
        }

      }
    } catch {
      console.log('There is something wrong')
    }
  }

  const getAttendanceOverview = async (
    page_number: null | number,
    employee_id: null | number,
    month: null | string,
    year:  null | string,
    entries: null | number,
    worksite: string
    ) => {

    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<IAttendanceOverview>>(API_ENDPOINT.GET_ATTENDANCE_OVERVIEW_FOR_JVD, {
        params: {
          page: page_number,
          employee_id: employee_id,
          month: month,
          year: year,
          entries: entries,
          worksite: worksite
        },
      })
      const result = await res

      setAttendanceOverview([])
      if (result?.data?.results?.data?.length) {
        setAttendanceDay(result?.data?.results?.days)
        setAttendanceOverview(result?.data?.results?.data)
        setCurrentPage(result?.data?.results?.current_page)
        setLastPage(result?.data?.results?.last_page)
        setPerPage(result?.data?.results?.per_page)
        setItemCount(result?.data?.results?.total)
        setFrom(result?.data?.results?.from)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAttendanceByUser = async (
    page_number: null | number,
    employee_id: null | string,
    date_from: null | string,
    date_to:  null | string,
    entries: null | number
    ) => {

    setShowLoading(true)
    try {
      const res = await axios.get<IAPI_Response<IAttendanceByUser>>(API_ENDPOINT.GET_ATTENDANCE_BY_USER_FOR_JVD, {
        params: {
          page: page_number,
          employee_id: employeeNo,
          date_from: date_from != null ? date_from : dateByUser.date_from,
          date_to: date_to != null ? date_to : dateByUser.date_to,
          entries: entries
        },
      })
      const result = await res

      setAttendanceByUser([])
      if (result?.data?.results?.data?.length) {
        setAttendanceByUser(result?.data?.results?.data)
        setCurrentPage1(result?.data?.results?.current_page)
        setLastPage1(result?.data?.results?.last_page)
        setPerPage1(result?.data?.results?.per_page)
        setItemCount1(result?.data?.results?.total)
      }
      setShowLoading(false)
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const getAttendanceToday = async (
      page_number: null | number,
      employee_id: number,
      date_from: string
  ) => {

    try {
      const response = await axios.get<IAPI_Response<IEmployeeAttendance>>(API_ENDPOINT.GET_ATTENDANCE_BY_DAY_FOR_JVD, {
        params: {
          page: page_number,
          entries: 10,
          searched: "",
          employee_id: employee_id ?? 0,
          worksite: selectedWorksiteOption.label,
          sort: "",
          sort_column: "",
          date_from: date_from
        },
      })
      const result = await response
      if (result?.data?.results?.data?.length) {
        setAttendanceToday(result?.data?.results?.data)
        setCurrentPage2(result?.data?.results?.current_page)
        setLastPage2(result?.data?.results?.last_page)
        setPerPage2(result?.data?.results?.per_page)
        setItemCount2(result?.data?.results?.total)
        setFrom2(result?.data?.results?.from)
      }else{
        setAttendanceToday([])
      }
    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)
    }, 3000);
  }

  const filterAttendanceOverview = async (value: any) => {
    setSelectedMonthOverview(value.month)
    setSelectedYearOverview(value.year)
    getAttendanceOverview(
      null,
      selectedEmployeeNoOverview,
      value.month != '' ? value.month : null,
      value.year != '' ? value.year : null,
        entries,
        selectedWorksiteOptionOverview.label
    );
  }

  const filterAttendanceByUser = async (value: any) => {
    // setEmployeeNo(value.employee_id != '' ? value.employee_id : null);
    setAttendanceByUserFilters({
      date_from: value.date_from != '' ? value.date_from : null,
      date_to: value.date_to != '' ? value.date_to : null,
      employee_id: value.employee_id != '' ? value.employee_id : null
    })
    setDateByUser({
      date_from: value.date_from != '' ? value.date_from : null,
      date_to: value.date_to != '' ? value.date_to : null
    })
    if(employeeNo != ""){
      getAttendanceByUser(
          null,
          value.employee_id != '' ? value.employee_id : null,
          value.date_from != '' ? value.date_from : null,
          value.date_to != '' ? value.date_to : null,
          entriesForByUser
      );
    }

  }

  const filterAttendanceToday = async (value: any) => {
    setDateByDay(value.date_from)
    setSelectedEmployeeTodayAttendance(value.employee_id != '' ? value.employee_id : null);
    getAttendanceToday(null, selectedEmployeeOption.value, value.date_from);
    // setDateByUser({
    //   date_from: value.date_from != '' ? value.date_from : null,
    //   date_to: value.date_to != '' ? value.date_to : null
    // })
    // getAttendanceByUser(
    //     null,
    //     value.employee_id != '' ? value.employee_id : null,
    //     value.date_from != '' ? value.date_from : null,
    //     value.date_to != '' ? value.date_to : null
    // );
  }

  const handleTimeStampChange = (e: any) => {
    const id = e.target.value;
    if(id != ""){
      attendaceByUser.map((byUser:any, index:any) =>{
            if(byUser.employee_attendance_id == selectedEmployeeAttendance){
              byUser?.location_and_images?.map((location:any, index2:any) => {
                if(parseInt(id) == location.id){
                  setLocationDetails({
                    latitude: location.latitude,
                    longitude: location.longitude,
                    address: location.address
                  });
                }
              })
            }
          }
      );

    }
  }

  const handleTimeStampChangeTodayAttendance = (e: any) => {
    const id = e.target.value;
    if(id != ""){
      attendanceToday.map((employee, index:any) =>{
            if(employee.employee_id == selectedEmployee){
              employee?.attendancesv2?.map((attendance, index2:any) => {
                attendance.locations_and_images.map(location => {
                  if(parseInt(id) == location.id){
                    setLocationDetails({
                      latitude: location.latitude,
                      longitude: location.longitude,
                      address: location.address
                    });
                  }
                })

              })
            }
          }
      );
    }
  }

  const handleExportByUser = async() => {

    if(employeeNo == ""){
      setShowError(true)
      setError("Please select employee.")
    }else if(dateByUser.date_from == ""){
      setShowError(true)
      setError("Please select date from.")
    }else if(dateByUser.date_to == ""){
      setShowError(true)
      setError("Please select date to.")
    }

    try {
      const res = await axios.get<IAPI_Response<IByUser[]>>(API_ENDPOINT.GET_ATTENDANCE_BY_USER_FOR_JVD_FOR_EXPORT, {
        params: {
          employee_id: employeeNo,
          date_from: dateByUser.date_from,
          date_to: dateByUser.date_to
        },
      })
      const result = await res

      if (result?.data?.results?.length) {

        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

        const ws = XLSX.utils.json_to_sheet(result?.data?.results);

        const wb = { Sheets: {'data' : ws}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, `${selectedEmployeeOptionByUser.label} (${dateByUser.date_from} - ${dateByUser.date_to})` + ".xlsx");

      }

    } catch {
      setShowError(true)
      setError(ERROR_MESSAGE.SYSTEM_ERROR)
    }

    setTimeout(() => {
      setShowError(false)

    }, 3000);
  }

  useEffect(() => {
    ;(async () => {
      getAllEmployee();
      getWorkSite()
      getAttendanceOverview(
        null,
          JSON.parse(localStorage.getItem("role") ?? "") == "Admin" ? null : Number(localStorage.getItem('employee_id')),
        null,
        null,
          entries,
          selectedWorksiteOptionOverview.label
      );
      getAttendanceToday(null,
          JSON.parse(localStorage.getItem("role") ?? "") == "Admin" ? selectedEmployeeTodayAttendance : Number(localStorage.getItem('employee_id')), dateByDay)

      if(localStorage.getItem("tab")){
        if(localStorage.getItem("tab") == "0"){
          setKey("attendance-overview")
        }else if(localStorage.getItem("tab") == "1"){
          setKey("byuser-table")
        }else if(localStorage.getItem("tab") == "2"){
          setKey("today-table")
        }
      }
    })()
  }, [])

  return (
    <Container fluid>
      <Row>
        <Col sm={6}>
          <h5 className='fw-bold'>Attendance Report</h5>
          <Breadcrumb className='breadcrumbs'>
            <Breadcrumb.Item className=' text-decoration-none' href='/home'>
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item className=' text-decoration-none' href='/payroll/setup/tax-rule-setup'>
              Attendance
            </Breadcrumb.Item>
            <Breadcrumb.Item active>Attendance Report</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Alert 
        type="error" 
        show={showError} 
        message={error} 
        setShow={() => { 
          setShowError(false);
          }
        }
      />
      <Alert
          type="success"
          show={showSuccess}
          message={success}
          setShow={() => {
            setShowSuccess(false);
          }
          }
      />


      <Tabs
        variant={'tabs'}
        className='tabs '
        id='controlled-tab-example'
        activeKey={key}
        onSelect={(k: any) => {
          setKey(k)
        }}
          // sx={{
          //   opacity: open ? 1 : 0,
          //   fontWeight: openDropdown === 0 ? 700 : 100,
          // }}
        >
        <Tab  eventKey='attendance-overview' title='Overview'>
          <Card className='border-0  p-0 bordered-card-tabs'>
            <Card.Body>

                <Formik
                  initialValues={
                    { 
                      employee_id: '',
                      month: now.getMonth() + 1,
                      year: now.getFullYear()
                    }
                  }
                  onSubmit={filterAttendanceOverview}
                  >
                  {({
                    values,
                    handleChange,
                    handleSubmit,
                    isSubmitting
                  }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col sm={9}>
                        <Row>
                          <Col sm={3}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Employee Name</Form.Label>
                              {/*<Form.Select*/}
                              {/*  name="employee_id"*/}
                              {/*  size='sm'*/}
                              {/*  value={values.employee_id}*/}
                              {/*  onChange={handleChange}*/}
                              {/*  >*/}
                              {/*  <option value="">Select Employee</option>*/}
                              {/*  {employees.map((employee, index) => (*/}
                              {/*    <option value={employee.employee_id} key={index}>{employee.first_name} {employee.last_name}</option>*/}
                              {/*  ))}*/}
                              {/*</Form.Select>*/}
                              <Select
                                  name="employee_id"
                                  value={selectedEmployeeOptionOverview}
                                  options={employeeOptions}
                                  onChange={(e: any) => {
                                    handleChangeDropdownOverview(e);
                                    handleSubmit();
                                  }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Month</Form.Label>
                              <Form.Select
                                  name="month"
                                  size='sm'
                                  value={values.month}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    handleSubmit();
                                  }}
                              >
                                <option value="" disabled>Select Month</option>
                                <option value="1">January</option>
                                <option value="2">February</option>
                                <option value="3">March</option>
                                <option value="4">April</option>
                                <option value="5">May</option>
                                <option value="6">June</option>
                                <option value="7">July</option>
                                <option value="8">August</option>
                                <option value="9">September</option>
                                <option value="10">October</option>
                                <option value="11">November</option>
                                <option value="12">December</option>
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Year</Form.Label>
                              <Form.Select
                                  name="year"
                                  size='sm'
                                  value={values.year}
                                  onChange={(e: any) => {
                                    handleChange(e);
                                    handleSubmit();
                                  }}
                              >
                                <option value="" disabled>Select Year</option>
                                {Array.from({ length: moment().year() - 2016 }, (_, i) => (
                                    <option key={i} value={moment().year() - i}>
                                      {moment().year() - i}
                                    </option>
                                ))}
                                {/*<option value="2024">2024</option>*/}
                                {/*<option value="2023">2023</option>*/}
                                {/*<option value="2022">2022</option>*/}
                                {/*<option value="2021">2021</option>*/}
                                {/*<option value="2020">2020</option>*/}
                                {/*<option value="2019">2019</option>*/}
                                {/*<option value="2018">2018</option>*/}
                                {/*<option value="2018">2017</option>*/}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Worksite</Form.Label>
                              <Select
                                  name="worksite_id"
                                  value={selectedWorksiteOptionOverview}
                                  options={worksiteOptions}
                                  onChange={(e: any) => {
                                    handleChangeDropdownWorksiteOverview(e);
                                    handleSubmit();
                                  }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                      {/*<Col sm={3}>*/}
                      {/*  <Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
                      {/*    <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
                      {/*    <br />*/}
                      {/*    <Button*/}
                      {/*        className='add-new-btn rounded me-1 text-white'*/}
                      {/*        variant='primary'*/}
                      {/*        type="submit"*/}
                      {/*        disabled={isSubmitting}*/}
                      {/*    >*/}
                      {/*      Search*/}
                      {/*    </Button>*/}
                      {/*  </Form.Group>*/}
                      {/*</Col>*/}
                    </Row>
                  </Form>
                )}
                </Formik>


              {/* LEGENDS*/}
              <Row>
                <Form.Group as={Col} sm="1">
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendancePresentIcon/>
                    &nbsp;Present
                  </Button>
                </Form.Group>
                <Form.Group as={Col} sm="1" >
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendanceAbsentIcon/>
                    &nbsp;Absent
                  </Button>
                </Form.Group>
                <Form.Group as={Col} sm="1" >
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendanceHafhdayIcon/>
                    &nbsp;Halfday
                  </Button>
                </Form.Group>
                <Form.Group as={Col} sm="1" >
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendanceLeaveIcon/>
                    &nbsp;Leave
                  </Button>
                </Form.Group>
                <Form.Group as={Col} sm="1" >
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendanceDayoffIcon/>
                    &nbsp;Restday
                  </Button>
                </Form.Group>
                <Form.Group as={Col} sm="1" >
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendanceHolidayIcon/>
                    &nbsp;Holiday
                  </Button>
                </Form.Group>
                <Form.Group as={Col} md="2">
                  <Button
                      className=' add-new-btn rounded text-secondary p-0 me-3'
                      variant='transparent'
                  >
                    <AttendanceWorkingHolidayIcon/>
                    &nbsp;Working Holiday
                  </Button>
                </Form.Group>
                <Form.Group as={Col} md="3">
                  {/*<Form.Label column="sm">No. of Entries</Form.Label>*/}
                </Form.Group>
                <Form.Group className="right-align" as={Col} md="1">
                  <Form.Select
                      size={'sm'}
                      name="work_shift_id"
                      value={entries}
                      onChange={handleChangeEntries}
                      style={{width: '80px'}}
                      // onBlur={handleBlur}
                      // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                  >
                    <option value="10" selected>10</option>
                    <option value="25" >25</option>
                    <option value="50" >50</option>
                    <option value="100" >100</option>

                  </Form.Select>
                </Form.Group>
              </Row>


              <Table responsive size={'sm'} className='bordered-tabs-top mt-3 table-overview'>
                <thead className='text-center'>
                  <tr>
                    <th></th>
                    <th></th>
                    {
                      attendaceDay.map((day, index) => 
                        <th key={index} className={day.weekend ? 'border-right' : ''}>
                          {(() => {
                            switch (day.day_name) {
                              case 'Monday':
                                return ('M')
                              case 'Tuesday':
                                return ('T')
                              case 'Wednesday':
                                return ('W')
                              case 'Thursday':
                                return ('T')
                              case 'Friday':
                                return ('F')
                              case 'Saturday':
                                return ('S')
                              case 'Sunday':
                                return ('S')
                            }
                          })()}
                        </th>
                      )
                    }
                    <th></th>
                  </tr>

                  <tr>
                    <th>No</th>
                    <th>Employee Name</th>
                    {
                      attendaceDay.map((day, index) => 
                        <th key={index} className={day.weekend ? 'border-right' : ''}>{day.day}</th>
                      )
                    }
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {attendaceOverview.length > 0 ? (
                      attendaceOverview.map((overview, index) => 
                        <tr className='mb-2 text-center' key={index}>
                          <td>{from + index}</td>
                          <td>
                            <Stack direction='row' spacing={1}>
                              <div>
                                {
                                  overview.photo != "" ?
                                      <Avatar
                                          sx={{ width: 20, height: 20 }}
                                          src={process.env.REACT_APP_IMAGE_URL+ overview.photo}
                                          className={"profile-avatar"}
                                      ></Avatar> :
                                      <Avatar
                                          sx={{ width: 20, height: 20}}
                                          src={""}
                                          className={"profile-avatar"}
                                      ></Avatar>
                                }
                              </div>
                              <div>{overview.last_name}, {overview.first_name}</div>
                            </Stack>
                          </td>
                          {
                            [...Array(attendaceDay.length)].map((e, i) =>
                             <td key={i}>
                                {(() => {
                                  switch (overview.schedules[i+1]) {
                                    case 'dayoff':
                                      return (
                                        <AttendanceDayoffIcon/>
                                      )
                                    case 'holiday':
                                      return (
                                        <AttendanceHolidayIcon/>
                                      )
                                    case 'leave':
                                      return (
                                        <AttendanceLeaveIcon/>
                                      )
                                    case 'halfday':
                                      return (
                                          <OverlayTrigger
                                              placement="left"
                                              delay={{ show: 250, hide: 400 }}
                                              overlay={
                                                <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                                  <Popover.Body>
                                                    {
                                                      ((overview.attendances[i+1]).map((attendance: any, index: any) => (
                                                          <>

                                                            <Row key={index}>
                                                              <Col><b>Clock In AM:</b></Col>
                                                              <Col>{ attendance.time_in }</Col>

                                                            </Row>
                                                            <Row key={index}>
                                                              <Col><b>Clock Out AM:</b></Col>
                                                              <Col>{ attendance.time_out }</Col>
                                                            </Row>
                                                            <Row key={index}>
                                                              <Col><b>Clock In PM:</b></Col>
                                                              <Col>{ attendance.time_in_pm }</Col>

                                                            </Row>
                                                            <Row key={index}>
                                                              <Col><b>Clock Out PM:</b></Col>
                                                              <Col>{ attendance.time_out_pm }</Col>
                                                            </Row>
                                                            <Row key={index}>
                                                              <Col><b>Shift From:</b></Col>
                                                              <Col>{ attendance.start_from }</Col>
                                                            </Row>
                                                            <Row key={index}>
                                                              <Col><b>Shift To:</b></Col>
                                                              <Col>{ attendance.end_to }</Col>
                                                            </Row>
                                                            {
                                                              overview.attendances.length > 1 ?
                                                                  <Row>--------------------------------------------</Row>
                                                                  : ""
                                                            }

                                                          </>
                                                      )))
                                                    }
                                                  </Popover.Body>
                                                </Popover>
                                              }
                                          >
                                            <Button
                                                className='add-new-btn rounded text-secondary p-0'
                                                variant='transparent'
                                            >
                                              <AttendanceHafhdayIcon/>
                                            </Button>
                                          </OverlayTrigger>
                                      )
                                      // return (
                                      //   <AttendanceHafhdayIcon/>
                                      // )
                                    case 'present':
                                      return (
                                        <OverlayTrigger
                                          placement="left"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '200px'}}>
                                              <Popover.Body>
                                                {
                                                  ((overview.attendances[i+1]).map((attendance: any, index: any) => (
                                                      <>

                                                        <Row key={index}>
                                                          <Col><b>Clock In AM:</b></Col>
                                                          <Col>{ attendance.time_in }</Col>

                                                        </Row>
                                                        <Row key={index}>
                                                          <Col><b>Clock Out AM:</b></Col>
                                                          <Col>{ attendance.time_out }</Col>
                                                        </Row>
                                                        <Row key={index}>
                                                          <Col><b>Clock In PM:</b></Col>
                                                          <Col>{ attendance.time_in_pm }</Col>

                                                        </Row>
                                                        <Row key={index}>
                                                          <Col><b>Clock Out PM:</b></Col>
                                                          <Col>{ attendance.time_out_pm }</Col>
                                                        </Row>
                                                        <Row key={index}>
                                                          <Col><b>Shift From:</b></Col>
                                                          <Col>{ attendance.start_from }</Col>
                                                        </Row>
                                                        <Row key={index}>
                                                          <Col><b>Shift To:</b></Col>
                                                          <Col>{ attendance.end_to }</Col>
                                                        </Row>
                                                        {
                                                          overview.attendances.length > 1 ?
                                                              <Row>--------------------------------------------</Row>
                                                            : ""
                                                        }

                                                      </>
                                                  )))
                                                }
                                              </Popover.Body>
                                            </Popover>
                                          }
                                          >
                                          <Button
                                            className='add-new-btn rounded text-secondary p-0'
                                            variant='transparent'
                                            >
                                            <AttendancePresentIcon/>
                                          </Button>
                                        </OverlayTrigger>
                                      )
                                    case 'absent':
                                      return (
                                        <AttendanceAbsentIcon/>
                                      ) 
                                    case 'empty':
                                      return ('')
                                  }
                                })()}
                             </td>
                            )
                          }
                          <td>{Object.keys(overview.attendances).length}/{attendaceDay.length}</td>
                        </tr>
                      )
                      ) : (
                      <tr className='no-item'>
                        <td colSpan={33}>{TEXT.NOT_FOUND}</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </Table>

              <Pagination
                itemsCount={itemsCount}
                itemsPerPage={itemsPerPage}
                currentPage={currentPage}
                lastPage={lastPage}
                setCurrentPage={setCurrentPage}
                alwaysShown={false}
                pageClicked={(page: number) => { 
                    getAttendanceOverview(
                      page,
                      selectedEmployeeNoOverview,
                      selectedMonthOverview,
                      selectedYearOverview,
                        entries,
                        selectedWorksiteOptionOverview.label
                    );
                  }
                }
              />
            </Card.Body>
          </Card>
        </Tab>

        <Tab className='p-0' eventKey='byuser-table' title='By User' tabClassName='tabs'>
          <Card className='border-0  mt-0 p-0 bordered-card-tabs'>
            <Card.Body>
              <Col sm={12}>
                <Formik
                  initialValues={
                    { 
                      employee_id: '',
                      date_from: '',
                      date_to: ''
                    }
                  }
                  validationSchema={validationSchema}
                  onSubmit={filterAttendanceByUser}
                >
                  {({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                    isSubmitting
                  }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col sm={2}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>Employee Name</Form.Label>
                          {/*<Form.Select */}
                          {/*  size='sm'*/}
                          {/*  name="employee_id"*/}
                          {/*  value={values.employee_id}*/}
                          {/*  onChange={handleChange}*/}
                          {/*  onBlur={handleBlur}*/}
                          {/*  isInvalid={touched.employee_id && !!errors.employee_id}*/}
                          {/*  >*/}
                          {/*  <option value="">Select Employee</option>*/}
                          {/*  {employees.map((employee, index) => (*/}
                          {/*    <option value={employee.employee_id} key={index}>{employee.first_name} {employee.last_name}</option>*/}
                          {/*  ))}*/}
                          {/*</Form.Select>*/}

                          <Select
                              name="employee_id"
                              value={selectedEmployeeOptionByUser}
                              options={employeeOptions}
                              onChange={(e: any) => {
                                handleChangeDropdownByUser(e);
                                handleSubmit();
                              }}
                          />

                          <Form.Control.Feedback type="invalid">
                            {errors.employee_id}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>Date From</Form.Label>
                          <InputGroup className='mb-2 ' size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control 
                              size={'sm'}
                              type='date'
                              placeholder='Friday, June 03, 2022'
                              name='date_from'
                              value={values.date_from}
                              onChange={(e: any) => {
                                handleChange(e);
                                values.date_from = e.target.value
                                handleSubmit();
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.date_from && !!errors.date_from}
                              max={moment().format("YYYY-MM-DD")}
                              />
                            <Form.Control.Feedback type="invalid">
                              {errors.date_from}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={2}>
                        <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>Date To</Form.Label>
                          <InputGroup className='mb-2 ' size={'sm'}>
                            <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                              <Calendar/>
                            </InputGroup.Text>
                            <Form.Control 
                              size={'sm'} 
                              type='date' 
                              placeholder='Friday, June 03, 2022'
                              name='date_to'
                              value={values.date_to}
                              onChange={(e: any) => {
                                handleChange(e);
                                values.date_to = e.target.value
                                handleSubmit();
                              }}
                              onBlur={handleBlur}
                              isInvalid={touched.date_to && !!errors.date_to}
                              max={moment().format("YYYY-MM-DD")}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.date_to}
                              </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Col>
                      <Col sm={1}>
                        {/*<Form.Group className='mb-3' controlId='formHorizontalEmail'>*/}
                        {/*  <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
                        {/*  <br />*/}
                        {/*  <Button */}
                        {/*    className='add-new-btn rounded me-1 text-white'*/}
                        {/*    variant='primary'*/}
                        {/*    type="submit" */}
                        {/*    disabled={isSubmitting}*/}
                        {/*    >*/}
                        {/*    Search*/}
                        {/*  </Button>*/}
                        {/*</Form.Group>*/}
                      </Col>

                      {/*<Col sm={2}>*/}
                      {/*  <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>*/}
                      {/*    <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>*/}
                      {/*    <br />*/}
                      {/*    */}
                      {/*  </Form.Group>*/}
                      {/*</Col>*/}
                      <Col sm={5} className='d-flex justify-content-end hidden'>
                        <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                          <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                          <br />
                          <Button
                              className='add-new-btn rounded text-white'
                              variant='primary'
                              type="button"
                              onClick={handleShowAllImage}
                          >
                            View All Images
                          </Button>
                          &nbsp;&nbsp;
                          <Button
                              className='add-new-btn rounded me-1 text-white'
                              variant='primary'
                              type="submit"
                              // disabled={isSubmitting}
                              onClick={handleOpenUpload}
                          >
                            Upload Attendance
                          </Button>
                          &nbsp;
                          <ReactToPrint
                              trigger={() =>
                                  <Button
                                      className='add-new-btn rounded me-1 text-white'
                                      variant='primary'
                                      type="submit"
                                      // disabled={isSubmitting}
                                      // onClick={handlePrint}
                                  >
                                    &nbsp; Print &nbsp;
                                  </Button>
                          }
                              content={() => componentRef.current}
                          />
                          &nbsp;&nbsp;
                          <Button
                              className='add-new-btn rounded me-1 text-white'
                              variant='primary'
                              type="submit"
                              // disabled={isSubmitting}
                              onClick={handleExportByUser}
                          >
                            Export Attendance
                          </Button>

                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                  )}
                </Formik>
              </Col>

              <>
                {employeeNo !== '' && (
                  <Stack direction='row' spacing={2}>

                      {employees.map((employee : any, index : any) => (
                        employee.employee_id == employeeNo ?
                            <>
                              <div>
                                {
                                  employee.photo != "" ?
                                    (<Avatar
                                        sx={{width: 33, height: 33}}
                                        src={process.env.REACT_APP_IMAGE_URL + employee.photo}
                                    ></Avatar>) :
                                    (<Avatar
                                        sx={{width: 33, height: 33}}
                                        src={""}
                                    ></Avatar>)
                                }
                              </div>

                              <div>
                                <h5 style={{ fontSize: '18px!important' }} className='fw-bold' key={index}>
                                  {employee.first_name} {employee.last_name}
                                </h5>
                              </div>
                            </>

                        : ''
                      ))}
                    <Form.Group className="right-align" as={Col} md="7">

                    </Form.Group>
                    <Form.Group className="right-align" as={Col} md="1">
                      <Form.Label className='fw-bold form-labels'>No. of Entries: </Form.Label>
                    </Form.Group>
                    <Form.Group as={Col} md="1" className='right-align d-flex justify-content-end hidden'>
                      <Form.Select
                          size={'sm'}
                          name="work_shift_id"
                          value={entriesForByUser}
                          onChange={handleChangeEntriesForByUser}
                          style={{width: '80%'}}
                          // onBlur={handleBlur}
                          // isInvalid={touched.work_shift_id && !!errors.work_shift_id}
                      >
                        <option value="10" selected>10</option>
                        <option value="25" >25</option>
                        <option value="50" >50</option>
                        <option value="100" >100</option>

                      </Form.Select>
                    </Form.Group>
                  </Stack>
                )}
              </>
             
              <Table responsive size='sm' className='bordered-tabs-top mt-3 table-by-user'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Day</th>
                    <th style={{ width: '80px' }} className='text-center'>
                      Status
                    </th>
                    <th className='text-center'>Clock In AM</th>
                    <th className='text-center'>Clock Out AM</th>
                    <th className='text-center'>Clock In PM</th>
                    <th className='text-center'>Clock Out PM</th>
                    <th className='text-center'>Worked Time</th>
                    <th className='text-center'>Overtime In</th>
                    <th className='text-center'>Overtime Out</th>
                    <th className='text-center'>Total Overtime</th>
                    <th className='text-center'>Late</th>
                    <th className='text-center'>Worksite</th>
                    <th className='text-center'>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <>
                    {attendaceByUser.length > 0 && employeeNo !== '' ? (
                      attendaceByUser.map((byUser, index) => 
                        <tr className='mb-2' key={index}>
                          <td className="table-row-middle-align">{byUser.date}</td>
                          <td className="table-row-middle-align">{byUser.day}</td>
                          <td className='text-center table-row-middle-align'>
                            <div className='d-grid'>
                            {(() => {
                              if (byUser.status == 'Leave') {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="info"
                                    size="sm"
                                    >
                                    Leave
                                  </Button>
                                )
                              } else if(byUser.status == 'Present') {
                                return (
                                  <Button 
                                    className=" p-1 pt-0 pb-0"
                                    style={{fontSize: '12px', color: 'white'}}
                                    variant="success"
                                    size="sm"
                                    >
                                    Present
                                  </Button>
                                )
                                } else if(byUser.status == 'Rest') {
                                return (
                                    <Button
                                        className=" p-1 pt-0 pb-0"
                                        style={{fontSize: '12px', color: 'white'}}
                                        variant="secondary"
                                        size="sm"
                                    >
                                      Rest Day
                                    </Button>
                                )
                              }

                              return (
                                <Button 
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: 'white'}}
                                  variant='danger'
                                  size="sm"
                                  >
                                  Absent
                                </Button>
                              )
                            })()}
                            </div>
                          </td>
                          <td className="text-center table-row-middle-align">{byUser.in_time != null ? byUser.in_time : '-'}</td>
                          <td className="text-center table-row-middle-align">{byUser.out_time != null ? byUser.out_time : '-'}</td>
                          <td className="text-center table-row-middle-align">{byUser.in_time_pm != null ? byUser.in_time_pm : '-'}</td>
                          <td className="text-center table-row-middle-align">{byUser.out_time_pm != null ? byUser.out_time_pm : '-'}</td>

                          {(() => {
                            if(byUser.status == 'Rest') {
                              return (<td className="text-center table-row-middle-align">-</td>)
                            }else{
                              return (<td className="text-center table-row-middle-align">{byUser.total_worked_time != null ? byUser.total_worked_time : '-'}</td>)
                           }
                          })()}
                          <td className="text-center table-row-middle-align">
                            {
                              byUser?.overtime_attendance?.length > 0 ?
                                byUser?.overtime_attendance.map((overtime, index) => (
                                    <>{index > 0 ? <br/>: ""} {overtime.overtime_in}</>
                                )) :
                                  '-'
                            }
                          </td>

                          <td className="text-center table-row-middle-align">
                            {
                              byUser?.overtime_attendance?.length > 0 ?
                                  byUser?.overtime_attendance.map((overtime, index) => (
                                      <>{index > 0 ? <br/>: ""} {overtime.overtime_out}</>
                                  )) :
                                  '-'
                            }
                          </td>
                          <td className="text-center table-row-middle-align">{byUser.total_overtime != null ? byUser.total_overtime : '-'}</td>
                          <td className="text-center table-row-middle-align">{byUser.total_late_time != null ? byUser.total_late_time : '-'}</td>
                          <td className="text-center table-row-middle-align">
                            {(() => {
                              if(byUser.worksite != null){
                                return (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={
                                          <Popover id={`popover-positioned-right`} style={{fontSize: '10px', width: '230px'}}>
                                            <Popover.Body>
                                              {
                                                <>

                                                <Row>
                                                <Col><b>Shift From:</b></Col>
                                                <Col>{moment(new Date("2023-01-01 " + byUser.start_from ?? "")).format("h:mm A")}</Col>
                                                </Row>
                                                <Row>
                                                  <Col><b>Shift To:</b></Col>
                                                  <Col>{moment(new Date("2023-01-01 " + byUser.end_to ?? "")).format("h:mm A")}</Col>
                                                </Row>
                                                </>
                                              }
                                            </Popover.Body>
                                          </Popover>
                                        }
                                    ><Button
                                        className='add-new-btn rounded p-0'
                                        variant='transparent'
                                    >{byUser.worksite}</Button></OverlayTrigger>
                                )
                              }
                              return ("-")
                            })()}
                          </td>
                          <td className='text-center table-row-middle-align'>
                            <Button 
                              className=" p-1 pt-0 pb-0"
                              style={{fontSize: '12px', color: '#F26930'}}
                              variant=" btn-transparent"
                              size="sm"
                              title="Show Locations"
                              onClick={() => handleShowLocation(byUser.employee_attendance_id)}
                              >
                              <Location/>
                            </Button>
                            <Button 
                              className=" p-1 pt-0 pb-0"
                              style={{fontSize: '12px', color: '#F26930'}}
                              variant=" btn-transparent"
                              size="sm"
                              title="Show Images"
                              onClick={() => handleShowImage(byUser.employee_attendance_id)}
                              >
                              <View/>
                            </Button>
                            {/*<Button*/}
                            {/*    className=" p-1 pt-0 pb-0"*/}
                            {/*    style={{fontSize: '12px', color: '#F26930'}}*/}
                            {/*    variant=" btn-transparent"*/}
                            {/*    size="sm"*/}
                            {/*    title="Edit Attendance"*/}
                            {/*    onClick={() => handleEdit(byUser.id, byUser.date)}*/}
                            {/*>*/}
                            {/*  <Write/>*/}
                            {/*</Button>*/}
                          </td>
                        </tr>
                      )
                      ) : (
                      <tr className='no-item'>
                        <td colSpan={14}>{TEXT.NOT_FOUND}</td>
                      </tr>
                    )}
                  </>
                </tbody>
              </Table>
              <Pagination
                itemsCount={itemsCount1}
                itemsPerPage={itemsPerPage1}
                currentPage={currentPage1}
                lastPage={lastPage1}
                setCurrentPage={setCurrentPage1}
                alwaysShown={false}
                pageClicked={(page: number) => { 
                  getAttendanceByUser(
                      page,
                      null,
                      null,
                      null,
                      entriesForByUser
                    );
                  }
                }
              />
            </Card.Body>
          </Card>
        </Tab>
        <Tab className='p-0' eventKey='today-table' title='By Day' tabClassName='tabs'>
          <Card className='border-0  mt-0 p-0 bordered-card-tabs'>
            <Card.Body>
              <Col sm={12}>
                <Formik
                    initialValues={
                      {
                        employee_id: '',
                        date_from: new Date().toISOString().split("T")[0]
                      }
                    }
                    validationSchema={validationSchema2}
                    onSubmit={filterAttendanceToday}
                >
                  {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={2}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Employee Name</Form.Label>
                              {/*<Form.Select*/}
                              {/*    size='sm'*/}
                              {/*    name="employee_id"*/}
                              {/*    value={values.employee_id}*/}
                              {/*    onChange={handleChange}*/}
                              {/*    onBlur={handleBlur}*/}
                              {/*    isInvalid={touched.employee_id && !!errors.employee_id}*/}

                              {/*>*/}
                              {/*  <option value="0">All Employees</option>*/}
                              {/*  {employees.map((employee, index) => (*/}
                              {/*      <option value={employee.employee_id} key={index}>{employee.first_name} {employee.last_name}</option>*/}
                              {/*  ))}*/}
                              {/*</Form.Select>*/}
                              <Select
                                  name="employee_id"
                                  value={selectedEmployeeOption}
                                  options={employeeOptions}
                                  onChange={(e: any) => {
                                    handleChangeDropdown(e)
                                    handleSubmit()
                                  }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.employee_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Worksite</Form.Label>

                              <Select
                                  name="worksite_id"
                                  value={selectedWorksiteOption}
                                  options={worksiteOptions}
                                  onChange={(e: any) => {
                                    handleChangeDropdownWorksite(e)
                                    handleSubmit()
                                  }}
                              />
                              <Form.Control.Feedback type="invalid">
                                {errors.employee_id}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col sm={2}>
                            <Form.Group className='mb-3' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>Date</Form.Label>
                              <InputGroup className='mb-2 ' size={'sm'}>
                                <InputGroup.Text id='basic-addon1' className='text-muted fw-bold bg-white'>
                                  <Calendar/>
                                </InputGroup.Text>
                                <Form.Control
                                    size={'sm'}
                                    type='date'
                                    placeholder='Friday, June 03, 2022'
                                    name='date_from'
                                    value={values.date_from}
                                    onChange={(e: any) => {
                                      handleChange(e);
                                      handleSubmit();
                                    }}
                                    onBlur={handleBlur}
                                    isInvalid={touched.date_from && !!errors.date_from}
                                    max={moment().format("YYYY-MM-DD")}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {errors.date_from}
                                </Form.Control.Feedback>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col sm={2}></Col>
                          <Col sm={4} className='d-flex justify-content-end hidden'>
                            <Form.Group className='mb-3 text-right' controlId='formHorizontalEmail'>
                              <Form.Label className='fw-bold form-labels'>&nbsp;</Form.Label>
                              <br />
                              <Button
                                  className='add-new-btn rounded text-white'
                                  variant='primary'
                                  type="button"
                                  onClick={() => handleShowAllImageToday(null)}
                              >
                                View All Images
                              </Button>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                  )}
                </Formik>
              </Col>

              <Table responsive size='sm' className='bordered-tabs-top mt-3 table-by-day'>
                <thead>
                <tr>
                  <th className='text-center'>No.</th>
                  <th className='text-center'>Employee</th>
                  <th className='text-center'>Status</th>
                  <th className='text-center'>Clock In AM</th>
                  <th className='text-center'>Clock Out AM</th>
                  <th className='text-center'>Clock In PM</th>
                  <th className='text-center'>Clock Out PM</th>
                  <th className='text-center'>Worked Time</th>
                  {/*<th className='text-center'>Break Time</th>*/}
                  {/*<th className='text-center'>Lunch In</th>*/}
                  {/*<th className='text-center'>Lunch Out</th>*/}
                  <th className='text-center'>Late</th>
                  <th className='text-center'>Actions</th>
                </tr>
                </thead>
                <tbody>
                <>
                  {attendanceToday.length > 0 ? (
                      attendanceToday.map((item, index) =>
                          <tr className="mb-2" key={index}>
                            <td>{from2 + index}</td>
                            <td>
                              <Stack direction='row' spacing={1}>
                                <div>
                                  {
                                    item.photo != "" ?
                                        <Avatar
                                            sx={{ width: 20, height: 20 }}
                                            src={process.env.REACT_APP_IMAGE_URL+ item.photo}
                                            className={"profile-avatar"}
                                        ></Avatar> :
                                        <Avatar
                                            sx={{ width: 20, height: 20}}
                                            src={""}
                                            className={"profile-avatar"}
                                        ></Avatar>
                                  }
                                </div>
                                <div>{item.last_name}, {item.first_name}</div>
                              </Stack>
                            </td>
                            <td className="text-center">
                              <div className="d-grid">
                                {(() => {
                                  if (item.leaves && item.leaves.length > 0) {
                                    return (
                                        <Button
                                            className=" p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="info"
                                            size="sm"
                                        >
                                          Leave
                                        </Button>
                                    )
                                  } else if(item.attendancesv2 && item.attendancesv2.length > 0) {
                                    return (
                                        <Button
                                            className=" p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="success"
                                            size="sm"
                                        >
                                          Present
                                        </Button>
                                    )
                                  } else if(item.employee_shifts && item.employee_shifts.length > 0){
                                    return (
                                        <Button
                                            className=" p-1 pt-0 pb-0"
                                            style={{fontSize: '12px', color: 'white'}}
                                            variant="secondary"
                                            size="sm"
                                        >
                                          Rest Day
                                        </Button>
                                    )
                                  }

                                  return (
                                      <Button
                                          className=" p-1 pt-0 pb-0"
                                          style={{fontSize: '12px', color: 'white'}}
                                          variant='danger'
                                          size="sm"
                                      >
                                        Absent
                                      </Button>
                                  )
                                })()}
                              </div>
                            </td>
                            <td className="text-center">{item.in_time != null ? item.in_time : '-'}</td>
                            <td className="text-center">{item.out_time != null ? item.out_time : '-'}</td>
                            <td className="text-center">{item.in_time_pm != null ? item.in_time_pm : '-'}</td>
                            <td className="text-center">{item.out_time_pm != null ? item.out_time_pm : '-'}</td>
                            <Runningtime
                                in_time={item.actual_in_time}
                                out_time={item.actual_out_time}
                                worked_time={item.total_worked_time}
                                worked_time_am={item.worked_time}
                                worked_time_pm={item.worked_time_pm}
                                type={'worked_time'}
                                break_in={item.actual_break_in}
                                break_out={item.actual_break_out}
                                in_time_pm={item.actual_in_time_pm}
                                out_time_pm={item.actual_out_time_pm}
                                start_from={item.start_from}
                                end_to={item.end_to}
                                break_start={item.break_start}
                                break_end={item.break_end}
                            />
                            {/*<Runningtime in_time={item.actual_in_time} out_time={item.actual_out_time} worked_time={item.worked_time} type={'break_time'} break_in={item.actual_break_in} break_out={item.actual_break_out}/>*/}
                            {/*<td className="text-center">{item.overtime_in != null ? item.overtime_in : '-'}</td>*/}
                            {/*<td className="text-center">{item.overtime_out != null ? item.overtime_out : '-'}</td>*/}
                            <td className="text-center">{item.total_late_time != null ? item.total_late_time : '-'}</td>
                            <td className="text-center">
                              <Button
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: '#F26930'}}
                                  variant=" btn-transparent"
                                  size="sm"
                                  title="Show Locations"
                                  onClick={() => handleShowLocationTodayAttendance(item.employee_id)}
                              >
                                <Location/>
                              </Button>
                              <Button
                                  className=" p-1 pt-0 pb-0"
                                  style={{fontSize: '12px', color: '#F26930'}}
                                  variant=" btn-transparent"
                                  size="sm"
                                  title="Show Images"
                                  onClick={() => handleShowImageTodayAttendance(item.employee_id)}
                              >
                                <View/>
                              </Button>
                            </td>
                          </tr>
                      )
                  ) : (
                      <tr className='no-item'>
                        <td colSpan={11}>{TEXT.NOT_FOUND}</td>
                      </tr>
                  )}
                </>
                </tbody>
              </Table>
              <Pagination
                  itemsCount={itemsCount2}
                  itemsPerPage={itemsPerPage2}
                  currentPage={currentPage2}
                  lastPage={lastPage2}
                  setCurrentPage={setCurrentPage2}
                  alwaysShown={false}
                  pageClicked={(page: number) => {
                    getAttendanceToday(
                        page,
                        selectedEmployeeTodayAttendance,
                        dateByDay
                    );
                  }
                  }
              />
            </Card.Body>
          </Card>
        </Tab>
      </Tabs>
      <br/>

      {/* All IMAGE MODAL*/}
      <Modal className='modal' size="lg" centered show={showAllImage} onHide={handleCloseAllImage}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>CAPTURED IMAGE/S123</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive size='sm'>
            <thead>
              <tr>
                <th className='text-center' style={{width: '20%'}}>Date</th>
                <th className='text-center'>Images</th>
              </tr>
            </thead>
            <tbody>
              {
                attendanceImageLocation.map((attendance, index) => (
                    <tr key={index}>
                      <td className="text-center" style={{verticalAlign: 'middle'}}>{attendance.date}</td>
                      <td>
                        <Row >
                          {
                            attendance.pictures.length > 0 ?
                              attendance.pictures.map((picture, index2) => (
                                    <Form.Group as={Col} key={index2} md="3" style={{padding:"10px"}}>
                                      <a href={IMAGE_BASE_URL + picture.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                                        <div className="card" >
                                          <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + picture.photo_url}/>
                                          <div className="card-body d-flex flex-column align-items-center" style={{padding: '5px'}}>
                                            {
                                              picture.attendance_status == "am_in_time"? <p className="card-text" style={{color:"#1EA66D", fontSize: '90%'}}>{attendance.in_time} - Clock in AM</p> :
                                                  picture.attendance_status == "am_out_time"? <p className="card-text" style={{color:"#DA2B5D", fontSize: '90%'}}>{attendance.out_time} - Clock out AM</p> :
                                                      picture.attendance_status == "pm_in_time"? <p className="card-text" style={{color:"#1EA66D", fontSize: '90%'}}>{attendance.in_time_pm} - Clock in PM</p> :
                                                          picture.attendance_status == "pm_out_time"? <p className="card-text" style={{color:"#DA2B5D", fontSize: '90%'}}>{attendance.out_time_pm} - Clock out PM</p> : ""
                                            }
                                            <p title={picture.address} className="card-text display-address" style={{color:"black", fontSize: '90%', marginTop: '-15px'}}>{picture.address}</p>
                                          </div>
                                        </div>
                                      </a>
                                    </Form.Group>


                              )) : <span className="text-center">No captured images.</span>
                          }
                        </Row>
                      </td>
                    </tr>
                ))
              }

            </tbody>
          </Table>

        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseAllImage}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* All IMAGE TODAY MODAL*/}
      <Modal className='modal' size="lg" centered show={showAllImageToday} onHide={handleCloseAllImageToday}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>CAPTURED IMAGE/S</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive size='sm'>
            <thead >
            <tr>
              <th className='text-center' style={{width: '20%'}}>Employees</th>
              <th className='text-center' >Images</th>
            </tr>
            </thead>
            <tbody>
            {
              attendanceImageLocationToday.map((attendance, index) => (
                  <tr key={index}>
                    <td className="text-center" style={{verticalAlign: 'middle'}}>{attendance.employee}</td>
                    <td>
                      <Row >
                        {
                          attendance.pictures.length > 0 ?
                              attendance.pictures.map((picture, index2) => (
                                  <Form.Group as={Col} key={index2} md="3" style={{padding:"10px"}}>
                                    <a href={IMAGE_BASE_URL + picture.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                                      <div className="card" >
                                        <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + picture.photo_url}/>
                                        <div className="card-body d-flex flex-column align-items-center" style={{padding: '5px'}}>
                                          {
                                            picture.attendance_status == "am_in_time"? <p className="card-text" style={{color:"#1EA66D", fontSize: '90%'}}>{attendance.in_time} - Clock in AM</p> :
                                                picture.attendance_status == "am_out_time"? <p className="card-text" style={{color:"#DA2B5D", fontSize: '90%'}}>{attendance.out_time} - Clock out AM</p> :
                                                    picture.attendance_status == "pm_in_time"? <p className="card-text" style={{color:"#1EA66D", fontSize: '90%'}}>{attendance.in_time_pm} - Clock in PM</p> :
                                                        picture.attendance_status == "pm_out_time"? <p className="card-text" style={{color:"#DA2B5D", fontSize: '90%'}}>{attendance.out_time_pm} - Clock out PM</p> : ""
                                          }
                                          <p title={picture.address} className="card-text display-address" style={{color:"black", fontSize: '90%', marginTop: '-15px'}}>{picture.address}</p>
                                        </div>
                                      </div>
                                    </a>
                                  </Form.Group>


                              )) : <span className="text-center">No captured images.</span>
                        }
                      </Row>
                    </td>
                  </tr>
              ))
            }

            </tbody>
          </Table>
          <br/>
          <Pagination
              itemsCount={itemsCount3}
              itemsPerPage={itemsPerPage3}
              currentPage={currentPage3}
              lastPage={lastPage3}
              setCurrentPage={setCurrentPage3}
              alwaysShown={false}
              pageClicked={(page: number) => {
                handleShowAllImageToday(
                    page
                );
              }
              }
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseAllImageToday}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* IMAGE MODAL*/}
      <Modal className='modal' size="lg" centered show={showImage} onHide={handleCloseImage}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>CAPTURED IMAGE/S</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {attendaceByUser.map((byUser, index) => (
                byUser.employee_attendance_id == selectedEmployeeAttendance && byUser.employee_attendance_id ?
                    (
                        byUser?.location_and_images != null ?
                            byUser?.location_and_images.map(location => (
                                <Form.Group as={Col} key={location.id} md="4" style={{padding:"10px"}}>
                                  <a href={IMAGE_BASE_URL + location.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                                    <div className="card" style={{width: "15rem"}}>
                                      <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + location.photo_url}/>
                                      <div className="card-body d-flex flex-column align-items-center">
                                        {
                                          location.attendance_status == "am_in_time"? <p className="card-text" style={{color:"#1EA66D"}}>{byUser.in_time} - Clock in AM</p> :
                                              location.attendance_status == "am_out_time"? <p className="card-text" style={{color:"#DA2B5D"}}>{byUser.out_time} - Clock out AM</p> :
                                                  location.attendance_status == "pm_in_time"? <p className="card-text" style={{color:"#1EA66D"}}>{byUser.in_time_pm} - Clock in PM</p> :
                                                      location.attendance_status == "pm_out_time"? <p className="card-text" style={{color:"#DA2B5D"}}>{byUser.out_time_pm} - Clock out PM</p> : ""
                                        }

                                      </div>
                                    </div>
                                  </a>
                                </Form.Group>
                            )) : <p>No Attendance yet. </p>
                    ): ""
            ))}
          </Row>


        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseImage}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* LOCATION MODAL*/}
      <Modal className='modal' size="lg" centered show={showLocation} onHide={handleCloseLocation}>
        <Modal.Header closeButton style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Modal.Title className='fw-bold'>LOCATIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
          <Form.Group as={Col} md="5">
            <Form.Label column="sm"></Form.Label>
          </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label column="sm">Timestamps</Form.Label>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4"></Form.Group>
            <Form.Group as={Col} md="4" className="position-relative">
              <Form.Select
                size={'sm'}
                name="marital_status"
                onChange={handleTimeStampChange}
                >
                <option value="">Select Timestamp</option>
                {attendaceByUser.map((byUser, index) => (
                    byUser.employee_attendance_id == selectedEmployeeAttendance?
                        (
                            (byUser?.attendancesv2?.map((attendance, index) => (
                                byUser?.location_and_images.map(location => (
                                    <option
                                        value={location.id}
                                        key={location.id} style={{
                                      color: location.attendance_status == "am_out_time" || location.attendance_status == "pm_out_time"? "#DA2B5D": location.attendance_status == "am_in_time" || location.attendance_status == "pm_in_time"? "#1EA66D":
                                          location.attendance_status == "break_out" || location.attendance_status == "break_in"? "#FBA91A": ""
                                    }}>

                                      {
                                        location.attendance_status == "am_in_time"? byUser.in_time +" - Clock in AM":
                                            location.attendance_status == "am_out_time"? byUser.out_time +" - Clock out AM":
                                                location.attendance_status == "pm_in_time"? byUser.in_time_pm +" - Clock in PM":
                                                    location.attendance_status == "pm_out_time"? byUser.out_time_pm +" - Clock out PM":
                                                        location.attendance_status == "check in"? " Check in":""
                                      }
                                    </option>
                                ))

                            )))
                        ): ""
                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <br/>
          <Row>
            <MapContainer
              style={{height: '400px'}}
              center={{ lat: 0, lng: 0 }}
              zoom={18}
              scrollWheelZoom={false}
              attributionControl={false}
              >
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker func={getLocation} latLongGiven={locationDetails}/>
            </MapContainer>
            <div className="text-center">
              Location : <b>{locationDetails.address}</b>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={handleCloseLocation}
            variant='primary text-white'
            size={'sm'}
            className='mx-2'
            >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* IMAGE MODAL TODAY ATTENDANCE*/}
      <Modal className='modal' size="lg" centered show={showImageTodayAttendance} onHide={handleCloseImageTodayAttendance}>
        <Modal.Header closeButton >
          <Modal.Title className='fw-bold'>CAPTURED IMAGE/S</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            {attendanceToday.map((employee, index) => (
                employee.employee_id == selectedEmployee?
                    (
                        employee?.attendancesv2.length > 0 ?
                            (employee?.attendancesv2?.map((attendance, index) => (
                                attendance.locations_and_images.map(location => (
                                    <Form.Group as={Col} key={location.id} md="4" style={{padding:"10px"}}>
                                      <a href={IMAGE_BASE_URL + location.photo_url} target="_blank" className="photo-a" rel="noreferrer">
                                        <div className="card" style={{width: "15rem"}}>
                                          <img style={{height: "130px", width: "auto", objectFit: "cover"}} className="card-img-top" alt={"Captured Image"} src={IMAGE_BASE_URL + location.photo_url}/>
                                          <div className="card-body d-flex flex-column align-items-center">
                                            {location.attendance_status == "am_in_time"? <p className="card-text" style={{color:"#1EA66D"}}>{attendance.in_time} - Clock in AM</p> :
                                                location.attendance_status == "am_out_time"? <p className="card-text" style={{color:"#DA2B5D"}}>{attendance.out_time} - Clock out AM</p> :
                                                    location.attendance_status == "pm_in_time"? <p className="card-text" style={{color:"#1EA66D"}}>{attendance.in_time_pm} - Clock in PM</p> :
                                                        location.attendance_status == "pm_out_time"? <p className="card-text" style={{color:"#DA2B5D"}}>{attendance.out_time_pm} - Clock out PM</p> : ""}
                                          </div>
                                        </div>
                                      </a>
                                    </Form.Group>
                                ))
                            ))) :
                            <p>No Attendance yet.</p>

                    ): ""

            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseImageTodayAttendance}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* LOCATION MODAL TODAY ATTENDANCE*/}
      <Modal className='modal' size="lg" centered show={showLocationTodayAttendance} onHide={handleCloseLocationTodayAttendance}>
        <Modal.Header closeButton style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
          <Modal.Title className='fw-bold'>LOCATIONS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group as={Col} md="5">
              <Form.Label column="sm"></Form.Label>
            </Form.Group>
            <Form.Group as={Col} md="2">
              <Form.Label column="sm">Timestamps</Form.Label>
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} md="4"></Form.Group>
            <Form.Group as={Col} md="4" className="position-relative">
              <Form.Select
                  size={'sm'}
                  name="marital_status"
                  onChange={handleTimeStampChangeTodayAttendance}
              >
                <option value="">Select Timestamp</option>
                {attendanceToday.map((employee, index) => (
                    employee.employee_id == selectedEmployee?
                        (
                            (employee?.attendancesv2?.map((attendance, index) => (
                                attendance.locations_and_images.map((location, index2) => (
                                    <option value={location.id} key={location.id} style={{
                                      color: location.attendance_status == "am_out_time" || location.attendance_status == "pm_out_time"? "#DA2B5D": location.attendance_status == "am_in_time" || location.attendance_status == "pm_in_time"? "#1EA66D":
                                          location.attendance_status == "break_out" || location.attendance_status == "break_in"? "#FBA91A": ""
                                    }}>
                                      {location.attendance_status == "am_in_time"? attendance.in_time + " - Clock in AM":
                                          location.attendance_status == "am_out_time"?  attendance.out_time + " - Clock out AM":
                                              location.attendance_status == "pm_in_time"? attendance.in_time_pm + " - Clock in PM":
                                                  location.attendance_status == "pm_out_time"?  attendance.out_time_pm + " - Clock out PM":
                                                      location.attendance_status == "check in"? "Check in":""}
                                    </option>
                                ))

                            )))

                        ): ""

                ))}
              </Form.Select>
            </Form.Group>
          </Row>

          <br/>
          <Row>
            <MapContainer
                style={{height: '400px'}}
                center={{ lat: 0, lng: 0 }}
                zoom={18}
                scrollWheelZoom={false}
                attributionControl={false}
            >
              <TileLayer
                  attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <LocationMarker func={getLocation} latLongGiven={locationDetails}/>
            </MapContainer>
            <div className="text-center">
              Location : <b>{locationDetails.address}</b>
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={handleCloseLocationTodayAttendance}
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* EDIT MODAL*/}
      <Modal className='modal' centered show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>Edit Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <h5 className="text-center">{moment(new Date(currentSelectedDate ?? "")).format('MMM-DD-YYYY')}</h5>
            </Row>
            <Row>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Clock in AM</Form.Label>
                <InputGroup size={'sm'}>
                  <InputGroup.Text id='basic-addon1'>
                    <Clock/>
                  </InputGroup.Text>
                  <Form.Control
                      size={'sm'}
                      type='datetime-local'
                      placeholder=''
                      name={'clock_in'}
                      value={showEmployeeAttendance.in_time}
                      onChange={handleChangeInTime}
                      isInvalid={showEmployeeAttendance.in_time == ""}

                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Clock out AM</Form.Label>
                <InputGroup size={'sm'}>
                  <InputGroup.Text id='basic-addon1'>
                    <Clock/>
                  </InputGroup.Text>
                  <Form.Control
                      size={'sm'}
                      type='datetime-local'
                      placeholder=''
                      name={'clock_out'}
                      value={showEmployeeAttendance.out_time}
                      onChange={handleChangeOutTime}
                      isInvalid={showEmployeeAttendance.out_time == ""}

                  />
                </InputGroup>
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Clock in PM</Form.Label>
                <InputGroup size={'sm'}>
                  <InputGroup.Text id='basic-addon1'>
                    <Clock/>
                  </InputGroup.Text>
                  <Form.Control
                      size={'sm'}
                      type='datetime-local'
                      placeholder=''
                      name={'in_time_pm'}
                      value={showEmployeeAttendance.in_time_pm}
                      onChange={handleChangeClockInPm}
                      isInvalid={showEmployeeAttendance.in_time_pm == ""}

                  />
                </InputGroup>
              </Form.Group>
              <Form.Group className="position-relative" as={Col} md="6">
                <Form.Label column="sm" className="required">Clock out PM</Form.Label>
                <InputGroup size={'sm'}>
                  <InputGroup.Text id='basic-addon1'>
                    <Clock/>
                  </InputGroup.Text>
                  <Form.Control
                      size={'sm'}
                      type='datetime-local'
                      placeholder=''
                      name={'out_time_pm'}
                      value={showEmployeeAttendance.out_time_pm}
                      onChange={handleChangeClockOutPm}
                      isInvalid={showEmployeeAttendance.out_time_pm == ""}

                  />
                </InputGroup>
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='outline-primary' size={'sm'} onClick={handleCloseEdit}>
            Cancel
          </Button>
          <Button
              variant='primary text-white'
              size={'sm'}
              className='mx-2'
              onClick={handleUpdate}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      {/* UPLOAD EMPLOYEES MODAL*/}
      <Modal className='modal' centered show={showUpload} onHide={handleCloseUpload}>
        <Modal.Header closeButton>
          <Modal.Title className='fw-bold'>Upload Attendance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label column="sm">Select Worksites</Form.Label>
                <Form.Select
                    name="worksite"
                    size='sm'
                    onChange={handleChangeWorksite}
                >
                  <option value="">Select Worksite</option>
                  {worksites.map((worksite, index) => (
                      <option value={worksite.id} key={index}>{worksite.work_site_name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label column="sm">Sub-worksites</Form.Label>
                <Form.Select
                    name="subworksite"
                    size='sm'
                    onChange={handleChangeSubworksite}
                >
                  <option value="">Select Sub-worksite</option>
                  {subworksites.map((subworksite, index) => (
                      <option value={subworksite.sub_work_site_id} key={index}>{subworksite.subworksites.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <br/>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label column="sm">Shifts</Form.Label>
                <Form.Select
                    name="shift"
                    size='sm'
                    onChange={handleChangeShift}
                >
                  <option value="">Select Shift</option>
                  {shifts.map((shift, index) => (
                      <option value={shift.shift_id} key={index}>{moment(new Date("2023-01-01 " + shift.shifts.time_from ?? "")).format("h:mm A")} - {moment(new Date("2023-01-01 " + shift.shifts.time_to ?? "")).format("h:mm A")}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} md="6">
                <Form.Label column="sm">Shift Type</Form.Label>
                <Form.Control
                    type='text'
                    size={'sm'}
                    name="shift_type"
                    id={'shift_type'}
                    disabled={true}
                    value={shiftType}
                />
              </Form.Group>

            </Row>
            <br/>
            <Row>
              <Form.Group as={Col} md="6">
                <Form.Label column="sm">Upload Excel File</Form.Label>
                <Form.Control
                    type='file'
                    size={'sm'}
                    name="photo"
                    onChange={handleChangeUploadFile}
                    id={'fileUpload'}
                />
              </Form.Group>
            </Row>

          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary text-white' className='mx-2' size={'sm'} onClick={handleUploadAttendance}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      <PrintAttendanceComponent ref={componentRef} attendance={attendaceByUser} employeeName={selectedEmployeeOptionByUser.label}/>

      <Loader show={showLoading}/>
    </Container>
  )
}
