import React, { useState } from "react";
import * as XLSX from "xlsx";
import {Table} from 'react-bootstrap';
import axios from "../../api";
import {API_ENDPOINT, SUCCESS_MESSAGE} from "../../utils/globalConstant";

interface ItemRow {
    ITEM?: number
    PRODUCT_CODE?: string;
    DESCRIPTION?: string;
    VENDOR_NO?: number;
    QTY?: number;
    UNIT?: string;
    UNIT_COST_BUYING?: number;
    TOTAL_COST_BUYING?: number;
    UNIT_COST_MARKUP?: number;
    SELLING_VAT_INC?: number;
    TOTAL?: number;
}

export default function Index() {
    const [items, setItems] = useState<ItemRow[]>([]);
    const [keyValueItems, setKeyValueItems] = useState<Record<string, string>>({});

    const handleFileUpload =  (event: React.ChangeEvent<HTMLInputElement>) => {
        // const file = event.target.files?.[0];
        // if (!file) return;
        //
        // const reader = new FileReader();
        //
        // reader.onload = (e) => {
        //     const binaryStr = e.target?.result as string;
        //     const workbook = XLSX.read(binaryStr, { type: "binary" });
        //     const sheetName = workbook.SheetNames[0];
        //     const sheet = workbook.Sheets[sheetName];
        //
        //     const rows: ItemRow[] = [];
        //     const range = XLSX.utils.decode_range(sheet["!ref"] as string); // Decode the sheet range
        //     let rowIndex = 1; // Start at row 2 (0-based index)
        //
        //     while (rowIndex <= range.e.r) { // Loop until the last row in range
        //         const row: any = {};
        //         let isEmptyRow = true;
        //
        //         for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
        //             const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
        //             const cell = sheet[cellAddress];
        //             const header = [
        //                 "ITEM",
        //                 "PRODUCT_CODE",
        //                 "DESCRIPTION",
        //                 "VENDOR_NO",
        //                 "QTY",
        //                 "UNIT",
        //                 "UNIT_COST_BUYING",
        //                 "TOTAL_COST_BUYING",
        //                 "UNIT_COST_MARKUP",
        //                 "SELLING_VAT_INC",
        //                 "TOTAL",
        //                 "MARGIN",
        //             ][colIndex]; // Map columns to headers
        //
        //             row[header] = cell ? cell.v : ""; // Extract cell value
        //             if (cell && cell.v !== "") {
        //                 isEmptyRow = false; // Mark row as not empty if a value exists
        //             }
        //         }
        //
        //         // Stop on the first empty row
        //         if (isEmptyRow) {
        //             console.log(`Empty row detected at row ${rowIndex + 1}, stopping.`);
        //             break;
        //         }
        //
        //         rows.push(row); // Push the non-empty row
        //         rowIndex++;
        //     }
        //
        //     console.log("Extracted Rows: ", rows);
        //     setItems(rows); // Update state with valid rows
        // };
        //
        // reader.readAsBinaryString(file);

        const file = event.target.files?.[0];
        const extractedItems: any[] = [];
        const extractedKeyValues: Record<string, string> = {};
        if (!file) return;

        const reader = new FileReader();

        reader.onload = async (e) => {
            const binaryStr = e.target?.result as string;
            const workbook = XLSX.read(binaryStr, { type: "binary" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets["Cost Sheet"];
            const range = XLSX.utils.decode_range(sheet["!ref"] as string);

            // Define the target keywords to look for (without colons)
            const targetKeywords = [
                "Distributor Name", "Company Name", "Total Vat Inc", "Input VAT", "Output VAT",
                "TOTAL Cost", "discount", "Selling Cost", "Freight Cost", "Client Rebate",
                "Representation", "VAT Difference", "Profitability (NP)"
            ];

            let rowIndex = 1; // Start at row 2 (index 1)

            // Header for Items Table
            const header = [
                "ITEM",
                "PRODUCT_CODE",
                "DESCRIPTION",
                "VENDOR_NO",
                "QTY",
                "UNIT",
                "UNIT_COST_BUYING",
                "TOTAL_COST_BUYING",
                "UNIT_COST_MARKUP",
                "SELLING_VAT_INC",
                "TOTAL",
            ];

            // 1. Parse the item rows until no valid header keys are present
            while (rowIndex <= range.e.r) {
                const rowData: any = {};
                let hasHeaderKey = false; // Flag to check if at least one key from the header exists in this row

                for (let colIndex = 0; colIndex < header.length; colIndex++) {
                    const cellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                    const cell = sheet[cellAddress];

                    if (cell && cell.v) {
                        rowData[header[colIndex]] = cell.v; // Map cell value to the corresponding header
                        hasHeaderKey = true; // Mark that a valid header key was found
                    }
                }

                // Stop the loop if no valid header keys are found in this row
                if (!hasHeaderKey) {
                    console.log(`No header keys found in row ${rowIndex + 1}, stopping items.`);
                    break;
                }

                extractedItems.push(rowData);
                rowIndex++;
            }

            // 2. Search for specific keywords and extract their values
            while (rowIndex <= range.e.r) {
                for (let colIndex = range.s.c; colIndex <= range.e.c; colIndex++) {
                    const keyCellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex });
                    const valueCellAddress = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex + 1 });

                    const keyCell = sheet[keyCellAddress];
                    const valueCell = sheet[valueCellAddress];

                    if (keyCell && keyCell.v) {
                        // Clean the key: remove colons and trim spaces
                        const cleanedKey = keyCell.v.toString().replace(/:$/, "").trim();

                        // Check if the cleaned key is in the target keywords
                        if (targetKeywords.includes(cleanedKey) && valueCell && valueCell.v) {
                            const key = cleanedKey;
                            const value = valueCell.v.toString().trim();
                            extractedKeyValues[key] = value;
                        }
                    }
                }
                rowIndex++;
            }

            if(extractedItems.length > 0){
                await axios.post(API_ENDPOINT.POST_SALE_ORDER, {
                    items: extractedItems,
                    sale_order: extractedKeyValues
                }).then(response => {
                    if (response?.data?.status * 1 === 1) {
                        alert("success")
                    }

                }).catch(error => {
                    console.log("error")
                })
            }

            console.log("Extracted Items: ", extractedItems);
            console.log("Extracted Key-Value Pairs: ", extractedKeyValues);

            setItems(extractedItems); // Set the table items
            setKeyValueItems(extractedKeyValues); // Set key-value pairs
        };

        reader.readAsBinaryString(file);


    };

    return (
        <div style={{ padding: "20px" }}>
            {/*<h1>Upload Excel File</h1>*/}
            {/*<input*/}
            {/*    type="file"*/}
            {/*    accept=".xlsx, .xls"*/}
            {/*    onChange={handleFileUpload}*/}
            {/*    style={{ marginBottom: "20px" }}*/}
            {/*/>*/}
            {/*<h2>Preview Data:</h2>*/}
            <div>
                <h1>Excel Upload</h1>
                <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} />
                <h2>Extracted Items:</h2>
                {items.length > 0 ? (
                    <Table responsive className='bordered-tabs-top mt-3'>
                        <thead>
                        <tr>
                            <th>Item</th>
                            <th>Product Code</th>
                            <th>Description</th>
                            <th>Vendor No</th>
                            <th>QTY</th>
                            <th>Unit</th>
                            <th>Unit Cost (Buying)</th>
                            <th>Total Cost (Buying)</th>
                            <th>Unit Cost (Markup)</th>
                            <th>Selling VAT Inc</th>
                            <th>Total</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td>{item.ITEM}</td>
                                <td>{item.PRODUCT_CODE}</td>
                                <td>{item.DESCRIPTION}</td>
                                <td>{item.VENDOR_NO}</td>
                                <td>{item.QTY}</td>
                                <td>{item.UNIT}</td>
                                <td>{item.UNIT_COST_BUYING}</td>
                                <td>{item.TOTAL_COST_BUYING}</td>
                                <td>{item.UNIT_COST_MARKUP}</td>
                                <td>{item.SELLING_VAT_INC}</td>
                                <td>{item.TOTAL}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <p>No data extracted</p>
                )}
            </div>

            <div>
                <Table responsive className='bordered-tabs-top mt-3'>
                    <thead>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                    </thead>
                    <tbody>
                    {Object.entries(keyValueItems).map(([key, value], index) => (
                        <tr key={index}>
                            <td>{key}</td>
                            <td>{value}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </div>
    );
};

